<template>
	<template v-for="(item, index) in menuList">
		<el-menu-item v-if="item.children === null || item.children.length === 0" :index="item.url"
					  @click="clickMenu(item)">
			<component v-if="item.icon.indexOf('el-icon') !== -1" :class="item.icon"></component>
			<el-icon v-else>
				<component :is="item.icon"></component>
			</el-icon>
			<span>{{ item.name }}</span>
		</el-menu-item>

		<el-sub-menu v-if="item.children !== null && item.children.length > 0" :index="item.url">
			<template #title>
				<component v-if="item.icon.indexOf('el-icon') !== -1" :class="item.icon"></component>
				<el-icon v-else>
					<component :is="item.icon"></component>
				</el-icon>
				<span>{{ item.name }}</span>
			</template>
			<myMenu :menuList="item.children"></myMenu>
		</el-sub-menu>
	</template>
</template>

<style scoped lang="less">


</style>

<script>
export default {
    name: 'myMenu',
	props: {
		menuList: Array,
	},
    components: {
    },
    watch: {
	},
    setup() {
    },
	beforeMount() {
    },
    mount() {

    },
    mounted() {
        this.menuFilter();
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    methods: {
        clickMenu: function (value) {
            //通过vuex将数据存储在store中
            this.$store.commit('mutationSelectTags', value)
        },
        menuFilter: function () {
            let that = this;
            let userBase = JSON.parse(localStorage.getItem("userBase"));
            if (!userBase) {
                localStorage.clear();
                this.$router.push('/login');
                return;
            }
            // let authorityUrlList = sysUser.authorityUrlList;
            // this.spliceFn(that.menuList, authorityUrlList);
        },
        spliceFn(treeData, authorityUrlList) {
            for (let i = 0; i < treeData.length; i++) {
                const obj = treeData[i]
                if (obj.interFace && authorityUrlList.indexOf(obj.interFace) === -1) {
                    treeData.splice(i, 1) // 删除元素
                    i = i - 1 // 调整索引值
                }
                // 对树节点的后代进行递归
                const tree = obj.children
                if (tree && tree.length > 0) {
                    this.spliceFn(tree, authorityUrlList)
                }
                if ((obj.children === null || obj.children.length === 0) && !obj.interFace) {
                    treeData.splice(i, 1) // 删除元素
                    i = i - 1 // 调整索引值
                }
            }
        },
    }
}
</script>
