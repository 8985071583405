import {createRouter, createWebHashHistory} from 'vue-router'
import home from '../views/home/home.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: home,
        redirect: {name: "index"},   //输入路由home会重定向到Preferred页面(一进来显示的页面)
        children:
            [
                {
                    path: '/index',
                    name: 'index',
                    component: () => import('../views/home/index.vue'),
                    meta: {
                        title: "个人中心"
                    }
                },
                {
                    path: '/content/typicalCases',
                    name: 'typicalCases',
                    component: () => import('../views/content/typicalCases'),
                    meta: {
                        title: "典型病例"
                    }
                },
                {
                    path: '/case/userCase',
                    name: 'userCase',
                    component: () => import('../views/case/userCase'),
                    meta: {
                        title: "病例列表"
                    }
                },
            ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
        meta: {
            title: "爱嘻AI"
        }
    },
    {
        path: '/case/caseDetail',
        name: 'caseDetail',
        component: () => import('../views/case/caseDetail'),
        meta: {
            title: "病例报告"
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async (to) => {
    if (to.meta.title) { // 判断是否有标题
        document.title = to.meta.title;
    }
});

export default router
