import axios from "@/axios/request";
import {JSEncrypt} from "jsencrypt";

export function blobToString (blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(blob);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
    });
}

export function rsa (obj, args, func) {
    axios.post(
        '/pcUserBase/getSession',
        {}
    ).then(res => {
        let data = res.data.data
        if (res.data.code === '0'){
            let thisObj = JSON.parse(JSON.stringify(obj));
            let encryptor = new JSEncrypt();
            encryptor.setPublicKey(data.publicKey);
            args.forEach(function (item, i){
                thisObj.sessionId = data.sessionId;
                thisObj[item] = encryptor.encrypt(thisObj[item]);
            })
            return func(thisObj);
        }
    })
}
