import axios from 'axios'
import { ElMessage } from 'element-plus'
import { ElNotification } from 'element-plus'
import router from '@/router'
import { blobToString } from "@/util/util";

// 创建 axios 实例
const service = axios.create({
    baseURL: '/api',
    timeout: 36000000
})
// 请求拦截
service.interceptors.request.use(config => {
    let token = localStorage.getItem("docker-token");
    if(token){
        config.headers['user-header'] = token;
    }
    return config
}) 
// 响应拦截
service.interceptors.response.use(res => {
    console.log(res.request.responseURL, res)
    if(res && !res.config.url.startsWith("https://") && !res.config.url.startsWith("http://")){
        if (res.request.responseType === "blob"){
            if (res.data.type === "application/json"){
                blobToString(new Blob([res.data])).then(result =>{
                    const data = JSON.parse(result);
                    if (data.code !== "0") {
                        ElMessage.error({
                            message: data.msg,
                            duration: 6000,
                            showClose: true
                        });
                    }
                })
            }else {
                const disposition = res.headers['content-disposition'];
                const matchArray = disposition.match(/filename="(.*)"/);
                const fileName = decodeURIComponent(matchArray[1]);
                const blob = new Blob([res.data]);
                const eLink = document.createElement('a');
                eLink.download = fileName;
                eLink.style.display = 'none';
                eLink.href = window.URL.createObjectURL(blob);
                document.body.appendChild(eLink);
                eLink.click();
                window.URL.revokeObjectURL(eLink.href); // 释放URL 对象
                document.body.removeChild(eLink);
            }
        }else if (res.data) {
            if (res.data.code !== "0") {
                ElMessage.error(res.data.msg);
            }
            if (res.data.code === "10103") {
                localStorage.clear();
                router.push('/login')
            }
        }
    }
    return res

}, error=> {
    if (error.response.status === 504 || error.response.status === 502) {
        ElNotification({
            title: '错误',
            message: '服务器被吃了⊙﹏⊙∥',
            type: 'error',
        })
    } else if (error.response.status === 403) {
        ElNotification({
            title: '错误',
            message: '啊哦，没有权限哟，亲',
            type: 'error',
        })
    }else if (error.response.status === 404) {
        ElNotification({
            title: '错误',
            message: '你好像迷路喽！',
            type: 'error',
        })
    }else if (error.response.status === 400) {
        ElNotification({
            title: '错误',
            message: '检查一下请求参数哦！',
            type: 'error',
        })
    }else if (error.response.status === 500) {
        ElNotification({
            title: '错误',
            message: '系统出错啦，快去找程序猿！',
            type: 'error',
        })
    }else {
        ElNotification({
            title: '错误',
            message: '我也不知道为什么呢！',
            type: 'error',
        })
    }
    return Promise.resolve(error);
})



export default service
