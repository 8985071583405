import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as Icons from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import 'element-plus/dist/index.css'
// import './assets/css/index.css'
import VueQuillEditor from 'vue-quill-editor/src'
import './assets/font/iconfont.css'

import 'quill/dist/quill.core.css' // 引入样式
import 'quill/dist/quill.snow.css' // snow theme
import 'quill/dist/quill.bubble.css' // bubble theme

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueQuillEditor)
    .use(ElementPlus, {locale: zhCn})
Object.keys(Icons).forEach(function (key) {
    app.component(Icons[key].name, Icons[key])
})
app.mount('#app')

