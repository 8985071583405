import {createStore} from 'vuex'

export default createStore({
    state: {
        stateTagsList: [
            {
                name: "个人中心",
                url: "/index"
            }
        ],
        isCollapse: false,
        shortcuts : [{
            text: '最近一周',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
            },
        }, {
            text: '最近一个月',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
            },
        }, {
            text: '最近三个月',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
            },
        }]
    },
    getters: {},
    mutations: {
        mutationSelectTags(state, data) {
            let result = false
            for (let i = 0; i < state.stateTagsList.length; i++) {
                if (state.stateTagsList[i].url === data.url) {
                    return result = true
                }
            }
            result === false ? state.stateTagsList.push(data) : ''
        },

        /**
         * 关闭tag标签
         */
        mutationCloseTag(state, data) {
            let result = state.stateTagsList.findIndex(item => item.url === data.url)
            state.stateTagsList.splice(result, 1)
        },
        changeIsCollapse(state, data) {
            state.isCollapse = data;
        }
    },
    actions: {},
    modules: {}
})
